<template>
  <div class="tw-relative tw--mt-3" v-if="dataLoaded">
    <div
      class="floating-overlay survey tw-px-10 tw-py-9"
      v-if="showPopupOverlay"
    >
      <h2
        class="tw-text-left tw-font-figtree tw-text-lg tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-8"
      >
        Editing questions or answers?
      </h2>
      <v-row>
        <v-col>
          <div class="tw-flex tw-items-start tw-mt-8">
            <p>✅</p>
            <p class="tw-ml-2 tw-text-left">
              Editing text to fix a typo? It won’t affect your response data
            </p>
          </div>
          <div class="tw-mt-4 tw-flex tw-items-start tw-mt-8">
            <p>⚠️</p>
            <p class="tw-ml-2 tw-text-left">
              Changing current questions or answer choices completely? This
              survey already has response(s), so your results data will be
              inaccurate.
            </p>
          </div>
          <div class="tw-mt-10">
            <v-btn
              color="primary"
              class="j-shadow-override tw-mb-5 tw-tracking-normal"
              block
              large
              borderless
              rounded
              @click="closePopupOverlay()"
              >Okay</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      class="floating-overlay survey tw-px-10 tw-py-9"
      v-if="showQuestionsOverlay"
    >
      <h2
        class="tw-text-left tw-font-figtree tw-text-lg tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-8"
      >
        Select Question(s)
      </h2>
      <v-row>
        <v-col>
          <v-btn
            rounded
            block
            large
            class="tw-rounded-full tw-bg-lighter-grey tw-text-dark-green tw-pl-8 tw-shadow-md tw-tracking-normal"
            @click="closeQuestionsOverlay('custom')"
          >
            <v-icon left dark>mdi-plus</v-icon>
            <v-spacer />
            Add Custom question
            <v-spacer />
          </v-btn>
          <h3
            class="tw-text-left tw-text-dark-green tw-text-base tw-mt-8 tw-mb-4 tw-font-semibold"
          >
            Popular questions
          </h3>
          <v-btn-toggle
            class="j-btn-toggle tw-flex-col tw-bg-transparent tw-w-full"
            v-model="surveyQuestionsSelectedTexts"
            borderless
            rounded
            multiple
          >
            <v-btn
              v-for="question in questions"
              :key="question.id"
              :value="question.text"
              rounded
              block
              large
              class="tw-rounded-full tw-bg-lighter-grey tw-text-dark-green tw-mb-5 tw-pl-8 tw-shadow-md tw-tracking-normal"
            >
              <v-icon v-if="!questionInArray(question)" left dark
                >mdi-plus</v-icon
              >
              <v-icon v-if="questionInArray(question)" left dark
                >mdi-check</v-icon
              >
              <v-spacer />
              {{ question.text }}
              <v-spacer />
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <div
        class="tw-absolute tw-flex tw-flex-col tw-items-end tw-top-8 tw-right-10"
      >
        <button
          class="tw-bg-white tw-shadow-custom tw-rounded-full tw-w-10 tw-h-10"
          type="button"
          @click="closeQuestionsOverlay()"
        >
          <v-icon color="#203848">mdi-close</v-icon>
        </button>
      </div>
    </div>
    <v-row class="tw-px-7">
      <v-col>
        <h2
          class="tw-text-left tw-font-figtree tw-text-lg tw-font-semibold tw-leading-30 tw-text-dark-green"
        >
          Edit Survey
        </h2>
        <p
          class="info-blurb tw-text-left tw-text-sm tw-font-figtree tw-font-normal tw-leading-22 tw-text-light-grey tw-py-4"
        >
          Preview and edit the questions and answers.
        </p>
      </v-col>
    </v-row>
    <div class="tw-bg-separator-grey tw-w-full tw-h-2 tw-mb-10"></div>
    <Draggable v-model="surveyQuestionsSelected" handle=".drag-handle">
      <transition-group
        v-for="(question, i) in surveyQuestionsSelected"
        :key="i"
      >
        <div :key="i" class="tw-px-7 tw-mb-10">
          <div
            class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-mx-2"
            :class="!question.addOptionAllowed ? 'tw-mb-5' : 'tw-mb-0'"
          >
            <img
              src="@/assets/svgs/drag.svg"
              alt="Reorder Question"
              class="tw-cursor-pointer tw--ml-4 tw-mr-3 drag-handle"
            />
            <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
              <input
                type="text"
                class="tw-text-left tw-text-dark-green tw-italic tw-text-base tw-font-semibold focus:tw-outline-none tw-w-full"
                v-model="question.text"
                @click.stop="shouldTextBeRemoved(question)"
                v-if="question.customQuestion || isCustomQuestion(question)"
              />
              <h3
                class="tw-text-left tw-text-dark-green tw-text-base tw-font-semibold"
                v-else
              >
                {{ question.text }}
              </h3>
              <img
                v-if="!surveyHasResponses"
                src="@/assets/svgs/surveys/delete-question.svg"
                alt="Delete Question"
                class="tw-cursor-pointer tw-w-7"
                @click.stop="deleteQuestion(question, i)"
              />
            </div>
          </div>
          <div v-if="!question.addOptionAllowed" class="tw-mb-5 tw-mx-2">
            <span
              class="tw-block tw-text-left tw-text-light-grey tw-text-sm tw-ml-2 tw--mt-4 tw-mb-6"
              >Your crew will select from the options below.</span
            >
            <div
              class="tw-bg-lighter-grey tw-p-9 tw-rounded-md tw-w-full tw-text-dark-green tw-font-semibold"
            >
              <div
                v-for="(option, i) in question.survey_options"
                :key="i"
                class="tw-mb-5 last:tw-mb-0"
              >
                {{ option.text }}
              </div>
            </div>
          </div>
          <div v-if="question.addOptionAllowed" class="tw-mb-5 tw-mx-2">
            <h4
              class="tw-text-left tw-text-light-grey tw-text-sm tw-mb-4 tw-ml-2"
            >
              Add at least two options.
            </h4>
            <div
              class="tw-mb-3"
              v-for="(option, i) in question.survey_options"
              :key="i"
            >
              <div class="tw-flex tw-flex-row tw-items-center">
                <v-expansion-panels
                  v-if="question.text === 'What date(s) work best?'"
                  :ref="'datepickerPanel_' + i"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div
                        class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                      >
                        <v-icon>mdi-calendar-blank</v-icon>
                        <div
                          class="tw-text-dark-green tw-text-base tw-font-semibold tw-mx-auto"
                        >
                          <span v-if="option.text.length">{{
                            getFormattedDateRange(
                              option.text[0],
                              option.text[1]
                            )
                          }}</span>
                          <span v-else>Select a date range</span>
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-date-picker
                        class="tw-py-4"
                        v-model="question.survey_options[i].text"
                        range
                        no-title
                        color="secondary"
                        @change="closePanel(i)"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div
                  v-else
                  class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full"
                >
                  <j-text-field
                    v-model="question.survey_options[i].text"
                    elevation="1"
                    light
                    background-color="#fafafa"
                    color="#203848"
                    class="tw-w-full"
                    hide-details
                    placeholder="Add Option"
                  />
                </div>
                <v-btn
                  class="tw-w-10 tw-h-10"
                  v-if="
                    question.survey_options.length > 2 && !surveyHasResponses
                  "
                  @click.stop="removeOption(question, i)"
                  icon
                >
                  <v-icon size="20" class="tw-text-charcoal">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="tw-text-left">
              <button
                class="tw-text-sm tw-text-light-grey tw-underline"
                @click.stop="addOption(question)"
                v-if="!surveyHasResponses"
              >
                Add Option
              </button>
            </div>
          </div>
          <div
            class="tw-mb-6 tw-flex tw-flex-row tw-justify-between tw-items-start"
            v-if="question.customQuestion"
          >
            <div class="tw-mx-2">
              <p class="tw-text-left tw-text-black tw-text-sm tw-mt-1 tw-mb-1">
                Allow Multiple selections
              </p>
              <p class="te-text left tw-text-light-grey tw-text-xs">
                Allow the crew to choose more than one
              </p>
            </div>

            <v-switch
              color="secondary"
              hide-details
              v-model="question.allowMultipleAnswers"
              inset
              class="tw-mt-0"
            />
          </div>
        </div>
        <div
          :key="i + '-separator'"
          class="tw-bg-separator-grey tw-w-full tw-h-2 tw-mb-10"
        ></div>
      </transition-group>
    </Draggable>
    <div class="tw-px-7">
      <v-btn
        rounded
        color="secondary"
        x-large
        block
        class="tw-tracking-normal tw-mb-5"
        @click="activateQuestionsOverlay()"
        v-if="!surveyHasResponses"
        ><v-icon>mdi-plus</v-icon>Add a Question</v-btn
      >
      <v-btn
        @click.native="updateSurvey"
        block
        rounded
        x-large
        :disabled="cantSubmit || surveyUpdatePending"
        :loading="surveyUpdatePending"
        color="primary"
        class="j-shadow-override tw-mb-5 tw-tracking-normal"
      >
        {{ buttonText }}
      </v-btn>
      <router-link
        class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block"
        :to="{ name: 'TripViewRoute', params: { id: trip.id } }"
      >
        Skip and Update Later
      </router-link>
    </div>
  </div>
</template>

<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { SUGGESTED_QUESTIONS, OTHER_QUESTIONS } from "@/enums/questions.js";
import Draggable from "vuedraggable";
export default {
  name: "SurveyEditForm",
  mixins: [FormattedDateRange],
  components: {
    Draggable
  },
  data() {
    return {
      countAsyncActions: 0,
      dataLoaded: false,
      showPopupOverlay: false,
      showQuestionsOverlay: false,
      initialSurveyQuestions: null,
      surveyQuestionsSelected: [],
      surveyQuestionsSelectedTexts: [],
      surveyHasResponses: false,
      questions: [...SUGGESTED_QUESTIONS, ...OTHER_QUESTIONS],
      questionObject: {
        slug: "",
        customQuestion: true,
        type: "MULTIPLE",
        text: "Type your question here",
        survey_options: [],
        addOptionAllowed: true,
        allowMultipleAnswers: false
      },
      buttonText: "Update Survey"
    };
  },
  watch: {
    surveyQuestionsSelectedTexts: {
      handler: function (val) {
        localStorage.setItem(
          "surveyQuestions",
          JSON.stringify(this.surveyQuestionsSelectedTexts)
        );

        this.adjustQuestionObjects(val);
      },
      deep: true
    },
    surveyQuestionsSelected: {
      handler: function (val) {
        val.forEach((q) => {
          if (q.text === "What date(s) work best?") {
            q.survey_options.forEach((o) => {
              if (o.text[0] && o.text[1]) {
                let temp = [...o.text];
                if (new Date(o.text[0]) > new Date(o.text[1])) {
                  o.text[0] = temp[1];
                  o.text[1] = temp[0];
                }
              }
            });
          }
        });
        localStorage.setItem(
          "surveyQuestionsObjects",
          JSON.stringify(this.surveyQuestionsSelected)
        );
      },
      deep: true
    }
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    surveyUpdatePending() {
      return this.$store.state["survey-builder"].isUpdatePending;
    },
    cantSubmit() {
      const hasInsufficientOptions = this.surveyQuestionsSelected.some(
        (question) => {
          return (
            question.text.length < 2 ||
            question.survey_options.length < 2 ||
            question.survey_options.some((option) => option.text.length === 0)
          );
        }
      );

      return (
        this.surveyQuestionsSelected.length === 0 || hasInsufficientOptions
      );
    }
  },
  methods: {
    shouldTextBeRemoved(question) {
      if (!question.id) question.text = "";
    },
    adjustQuestionObjects(texts) {
      this.surveyQuestionsSelected.forEach((obj, index) => {
        if (!texts.includes(obj.text) && !obj.customQuestion) {
          this.surveyQuestionsSelected.splice(index, 1);
        }
      });

      const missingQuestions = texts.filter(
        (text) => !this.surveyQuestionsSelected.some((obj) => obj.text === text)
      );
      this.questions.forEach((q) => {
        if (missingQuestions.includes(q.text)) {
          this.surveyQuestionsSelected.push(q);
        }
      });
    },
    cloneObject(obj) {
      if (obj === null || typeof obj !== "object" || "isActiveClone" in obj) {
        return obj;
      }
      let temp = null;
      if (obj instanceof Date) {
        temp = new Date(obj);
      } else {
        temp = obj.constructor();
      }
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj["isActiveClone"] = null;
          temp[key] = this.cloneObject(obj[key]);
          delete obj["isActiveClone"];
        }
      }
      return temp;
    },
    activateQuestionsOverlay() {
      this.showQuestionsOverlay = true;
      document.querySelector("body").classList.add("fixed");
    },
    activatePopupOverlay() {
      this.showPopupOverlay = true;
      document.querySelector("body").classList.add("fixed");
    },
    closeQuestionsOverlay(custom = null) {
      if (custom) {
        let question = this.cloneObject(this.questionObject);
        this.surveyQuestionsSelected.unshift(question);
      }
      this.showQuestionsOverlay = false;
      document.querySelector("body").classList.remove("fixed");
    },
    closePopupOverlay() {
      this.showPopupOverlay = false;
      document.querySelector("body").classList.remove("fixed");
    },
    questionInArray(question) {
      return this.surveyQuestionsSelected.some((q) => q.text === question.text);
    },
    isCustomQuestion(question) {
      return !this.questions.some((q) => q.text === question.text);
    },
    closePanel(i) {
      const panelRef = this.$refs["datepickerPanel_" + i][0];
      if (panelRef) panelRef.internalValue = undefined;
    },
    addOption(question) {
      if (question.text === "What date(s) work best?") {
        question.survey_options.push({ text: [] });
      } else question.survey_options.push({ text: "" });
    },
    removeOption(question, i) {
      question.survey_options.splice(i, 1);
    },
    deleteQuestion(q, i) {
      if (q.customQuestion) {
        this.surveyQuestionsSelected.splice(i, 1);
      } else {
        this.surveyQuestionsSelected.splice(i, 1);
        let index = this.surveyQuestionsSelectedTexts.indexOf(q.text);
        if (index !== -1) {
          this.surveyQuestionsSelectedTexts.splice(index, 1);
        }
      }
    },
    convertDatesToStrings(surveyQuestions) {
      for (const question of surveyQuestions) {
        if (question.text === "What date(s) work best?") {
          const options = question.survey_options;
          options.forEach((option, index) => {
            if (Array.isArray(option.text)) {
              this.$set(
                question.survey_options[index],
                "text",
                option.text.join(",")
              );
            }
          });
        }
      }
    },
    async updateSurvey() {
      this.convertDatesToStrings(this.surveyQuestionsSelected);

      const promises = [];

      // Iterate through initial questions to see for deleted questions
      this.initialSurveyQuestions.forEach((initialQuestion) => {
        if (
          !this.surveyQuestionsSelected.some(
            (q) => q.id && q.id === initialQuestion.id
          )
        ) {
          promises.push(this.deleteSurveyQuestion(initialQuestion));
        }
      });

      // Iterate through questions to see for newly added questions
      this.surveyQuestionsSelected.forEach((question) => {
        if (!question.id) {
          promises.push(this.addNewQuestion(question));
        } else {
          let original = this.initialSurveyQuestions.find(
            (q) => q.id === question.id
          );
          this.checkSurveyQuestionChanges(original, question);
        }
      });

      // Wait for all promises to resolve
      await Promise.all(promises);

      //refetch the survey questions
      const newSurveyQuestions = await this.$store.dispatch(
        "survey-builder/get",
        this.$route.params.surveyId
      );

      // Create a copy of newSurveyQuestions
      const updatedNewSurveyQuestions = [...newSurveyQuestions.questions];

      // Sort updatedNewSurveyQuestions based on the indexes of questions in surveyQuestionsSelected
      updatedNewSurveyQuestions.sort((a, b) => {
        const indexA = this.surveyQuestionsSelected.findIndex(
          (q) => q.text === a.text
        );
        const indexB = this.surveyQuestionsSelected.findIndex(
          (q) => q.text === b.text
        );
        return indexA - indexB;
      });

      //update all questions weight property?
      let weightPromises = [];
      let weightIndex = 0;
      updatedNewSurveyQuestions?.forEach((question) => {
        weightPromises.push(this.patchQuestionWeight(question, weightIndex));
        weightIndex++;
      });

      // Wait for all weight promises to resolve
      await Promise.all(weightPromises);

      this.buttonText = "Please wait...";
      this.$store.dispatch("meta/showGlobalAlert", {
        type: "success",
        text: "Survey updated successfully!",
        timeout: 2000
      });
      this.$router.push({ name: "SurveyListRoute" });
    },
    checkSurveyQuestionChanges(original, question) {
      if (original.text !== question.text) {
        //patch question
        this.patchQuestion(question);
      }

      //iterate through original options and
      original.survey_options.forEach((originalOption) => {
        if (
          !question.survey_options.some(
            (o) => o.id && o.id === originalOption.id
          )
        ) {
          this.deleteSurveyOption(originalOption.id);
        }
      });

      // Iterate through options to see for newly added options
      question.survey_options.forEach((option) => {
        //!original.survey_options.some((o) => o.text === option.text) &&
        if (!option.id) {
          this.addNewSurveyOption(question.id, option);
        } else {
          let originalOption = original.survey_options.find(
            (o) => o.id === option.id
          );
          this.checkSurveyOptionChanges(originalOption, option);
        }
      });
    },
    checkSurveyOptionChanges(original, option) {
      if (original.text !== option.text) {
        //patch question
        this.patchSurveyOption(option);
      }
    },
    async deleteSurveyQuestion(question) {
      const deleteOptionPromises = question.survey_options.map((option) => {
        return this.deleteSurveyOption(option.id);
      });

      // Wait for all deleteSurveyOption calls to complete
      await Promise.all(deleteOptionPromises);

      // Once all options are deleted, remove the question
      await this.$store.dispatch("survey-question/remove", question.id);
    },
    async deleteSurveyOption(id) {
      await this.$store.dispatch("survey-option/remove", id);
    },
    async addNewQuestion(question) {
      let q = {
        surveyId: this.$route.params.surveyId,
        type: "MULTIPLE",
        text: question.text,
        isOptional: false,
        addOptionAllowed: question.addOptionAllowed ? true : false,
        allowMultipleAnswers: question.allowMultipleAnswers ? true : false
      };

      let resPostQuestion = await this.$store.dispatch(
        "survey-question/create",
        q
      );

      if (resPostQuestion && question.survey_options) {
        question.survey_options.forEach((option) => {
          this.addNewSurveyOption(resPostQuestion.id, option);
        });
      }
    },
    async patchQuestion(question) {
      await this.$store.dispatch("survey-question/patch", [
        question.id,
        {
          text: question.text
        }
      ]);
    },
    async patchQuestionWeight(question, index) {
      await this.$store.dispatch("survey-question/patch", [
        question.id,
        {
          weight: index
        }
      ]);
    },
    async addNewSurveyOption(id, option) {
      let o = {
        surveyId: this.$route.params.surveyId,
        surveyQuestionId: id,
        text: option.text
      };

      await this.$store.dispatch("survey-option/create", o);
    },
    async patchSurveyOption(option) {
      await this.$store.dispatch("survey-option/patch", [
        option.id,
        {
          text: option.text
        }
      ]);
    }
  },
  async beforeMount() {
    let surveyBuilder = null;

    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Trip Survey",
      bgColor: "#203848",
      fontColor: "#E6FFA1",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: false,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    if (this.trip && this.trip.surveys && this.trip.surveys.length > 0) {
      surveyBuilder = await this.$store.dispatch(
        "survey-builder/get",
        this.$route.params.surveyId
      );
    }

    //if no survey builder go back
    if (!surveyBuilder) {
      this.$router.go(-1);
      return;
    }

    if (surveyBuilder.userIdsHaveResponded.length > 0) {
      this.activatePopupOverlay();
      this.surveyHasResponses = true;
    }

    this.$set(this, "surveyQuestionsSelected", surveyBuilder.questions);
    this.surveyQuestionsSelected.forEach((q) =>
      this.surveyQuestionsSelectedTexts.push(q.text)
    );

    //create an object of questions containing survey_options entry
    this.questions = this.questions.map((item) => {
      const { options, ...rest } = item;

      let opts = options.map((o) => {
        return {
          text: o
        };
      });

      return {
        ...rest,
        survey_options: opts
      };
    });

    //set initial copy for recognizing whether or not a question was deleted
    this.initialSurveyQuestions = this.cloneObject(
      this.surveyQuestionsSelected
    );

    //initialize questions
    this.surveyQuestionsSelected.forEach((question) => {
      if (question.text === "What date(s) work best?") {
        const options = question.survey_options;
        options.forEach((option, index) => {
          this.$set(
            question.survey_options[index],
            "text",
            option.text.split(",")
          );
        });
        this.$set(question, "isOpen", false);
        this.$set(question, "dates", null);
      } else {
        question.survey_options.sort((a, b) => a.id - b.id);
      }
      if (
        question.addOptionAllowed &&
        !question.survey_options.length &&
        question?.dates === undefined
      ) {
        // Ensuring at least one option is available on page load, except for questions with a datepicker
        question.survey_options.push({ text: "" });
      }
    });

    this.dataLoaded = true;
  },
  updated() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });
  },

  destroyed() {
    document.querySelector("body").classList.remove("fixed");
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: false
    });
    localStorage.removeItem("surveyQuestions");
    localStorage.removeItem("surveyQuestionsObjects");
  }
};
</script>

<style lang="scss">
.j-text-field.v-text-field.v-input--is-disabled input {
  @apply tw-text-dark-green;
}

.j-link {
  @apply tw-block tw-mx-auto  tw-text-base tw-font-semibold tw-leading-6 tw-underline tw-cursor-pointer;
}

button.v-btn.v-btn--active {
  background-color: #203848 !important;
  color: #ffffff !important;

  .v-icon:before {
    color: #ffffff;
  }
}
</style>
